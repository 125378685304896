import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Storage, DataStore, Auth } from 'aws-amplify';
import AddLocation from '../components/AddLocation/AddLocation';
import LinearProgress from '@mui/material/LinearProgress';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChipAutocomplete from '../components/ChipAutocomplete';
import Alert from "@mui/material/Alert";
import { TagTypes } from '../types/Model';
import { Note, NoteTags, Tag } from '../models';
import Typography from '@mui/material/Typography';
Storage.configure({ level: 'protected' });

const EntryPage: React.FC = () => {
    const [notes, setNotes] = useState("");
    const [title, setTitle] = useState("");
    const [selectedTags, setSelectedTags] = useState<Array<TagTypes>>([])
    const [lat, setLat] = useState(53.34);
    const [long, setLong] = useState(-1.49);
    const [locationLoading, setLocationLoading] = useState(false);
    const [locationChanged, setLocationChanged] = useState(false);
    const [filesToUpload, setFilesToUpload] = useState<FileList | null>(null);
    const [isSubmitting, setSubmitting] = useState(false);
    const [identityId, setIdentityId] = useState("");
    const [errors, setErrors] = useState<Array<string>>([]);

    const [mapOpen, setMapOpen] = useState(false);

    const [tags, setTags] = useState<Array<TagTypes>>([]);


    useEffect(() => {
        const setTagsFunc = async () => {
            const loadedTags = await DataStore.query(Tag)
            const identity = await Auth.currentCredentials();
            setIdentityId(identity.identityId);
            setTags(loadedTags);
        };
        setTagsFunc();
    }, [])

    const handleLocationOpenClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setLocationLoading(true);
        await addLocation();
        setLocationLoading(false);
        setMapOpen(true);
    };

    const handleMapClose = () => {
        setMapOpen(false);
    };

    const handleNotesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNotes(event.target.value);
        setErrors([]);
    }

    const addLocation = async () => {
        if (locationChanged) {
            return;
        }
        if ("geolocation" in navigator) {

            await new Promise<GeolocationPosition>((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            }).then(position => {
                setLat(position.coords.latitude);
                setLong(position.coords.longitude);
            }, err => {
                console.log("err: ", err)
            });
        } else {
            alert("need location services")
        }
    }

    const handleUpdateLocation = (lat: number, lng: number) => {
        setLocationChanged(true);
        setLat(lat);
        setLong(lng);
        setErrors([]);
    }


    const imageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            Array.from(event.target.files).forEach(f => {
                if (f.name.includes("/")) {
                    setErrors(["Image names cannot contain a '/'"])
                    return;
                }
            })

            if (event.target.files.length > 5) {
                setErrors(["You can only add up to 5 images"]);
                return;
            }
        }
        // TODO upload them when selected so we can have thumbnails, then delete if inot submitted
        // TODO resize uploaded images
        setFilesToUpload(event.target.files)
    }

    const isValid = () => {
        const errors = []
        if (!title) {
            errors.push("Title is required")
        }

        if (!notes) {
            errors.push("Note is required")
        }

        if (!locationChanged) {
            errors.push("Adding a Location is required")
        }

        if (errors.length !== 0) {
            setErrors(errors);
            return false;
        }
        return true;
    }

    const submit = async () => {
        if (!isValid()) {
            return;
        }
        setSubmitting(true);
        const fileNames: Array<string> = []
        if (filesToUpload) {

            const fileArray = Array.from(filesToUpload);
            await Promise.all(fileArray.map(async f => {
                try {
                    const result = await Storage.put(f.name, f);
                    fileNames.push(identityId + "/" + result.key);

                } catch (err) {
                    console.log("Error uploading file", err, f.name)
                }
            })).catch(() => {
                alert("Failed to upload note images, please try again")
            });
        }

        try {
            const n = await DataStore.save(
                new Note({
                    title: title,
                    note: notes,
                    coords: {
                        lat: lat,
                        long: long,
                    },
                    images: fileNames
                })
            );
            for (let t of selectedTags) {
                let tag;
                if (!t.id) {
                    tag = await DataStore.save(
                        new Tag({
                            label: t.inputValue ?? t.label
                        })
                    );
                } else {
                    tag = await DataStore.query(Tag, t.id)
                }

                if (!tag) {
                    continue;
                }
                await DataStore.save(
                    new NoteTags({
                        note: n,
                        tag: tag
                    })
                )

            }
        } catch (err) {
            console.log("failed to store using datastore")
            alert("Failed to save note, please try again")
        }
        clear();
    }

    const clear = () => {
        setNotes("");
        setTitle("")
        setSelectedTags([])
        setFilesToUpload(null)
        setSubmitting(false);
        setLocationChanged(false);
    }

    const updateTags = (tags: Array<TagTypes>) => {
        setSelectedTags(tags);
    }

    return (<div className="entry">
        {mapOpen ?
            <AddLocation onClose={handleMapClose} initialLat={lat} initialLng={long} updateLocation={handleUpdateLocation} />
            :
            <Paper sx={{ display: "flex", flexDirection: "column" }}>
                <TextField
                    sx={{ margin: "8px" }}
                    id="title"
                    label="Title"
                    value={title}
                    onChange={(e) => { setTitle(e.target.value); setErrors([]); }}
                />
                <TextField
                    sx={{ margin: "8px" }}
                    id="notes"
                    label="Notes"
                    value={notes}
                    onChange={handleNotesChange}
                    multiline
                    rows={5}
                />
                <Button variant="outlined" color="secondary" onClick={handleLocationOpenClick} sx={{ margin: "8px" }} endIcon={locationChanged ? <CheckCircleIcon sx={{ color: "green" }} /> : <></>}>Add Location</Button>
                {locationLoading && <LinearProgress />}
                <Button variant="outlined" color="secondary" component="label" sx={{ margin: "8px" }}>
                    Add Pictures
                    <input type="file" hidden multiple onChange={imageUpload} />
                </Button>
                {filesToUpload && <Typography variant="subtitle1">{filesToUpload.length} {filesToUpload.length > 1 ? "files" : "file"} selected</Typography>}
                <ChipAutocomplete tags={tags} updateSelected={updateTags} />
                <Button variant="contained" disabled={isSubmitting || errors.length > 0} sx={{ margin: "8px" }} onClick={submit}>Submit</Button>
                {isSubmitting && <LinearProgress />}
                <Button variant="outlined" disabled={isSubmitting || errors.length > 0} onClick={() => clear()} sx={{ margin: "8px" }}>Clear</Button>
                {
                    errors.map((e: string, k: number) => (
                        <Alert severity="error" key={k}>{e}</Alert>
                    ))
                }
            </Paper>}
    </div>)

}

export default EntryPage;